import React from 'react';

import styled from 'styled-components';
import { Card, Skeleton, Row, Col, Statistic } from 'antd';
import ReactCountryFlag from 'react-country-flag';

import Layout from '@/components/layout/public';
import Hero from '@/components/public/page-hero';
import Callout, { LeaksCallout } from '@/components/public/callout';
import Pricing from '@/components/public/pricing';
import Faq from '@/components/public/faq';
import SEO from '@/components/seo';
import Content from '@/components/content';
import { serversUrl } from '@/routes';
import { iModel as iModelServers } from '@/state/models/servers';


const Wrapper = styled(Content)`
	margin-bottom: 100px;

	h2 {
		text-align: center;
		margin-bottom: 40px;
		font-weight: 800;
	}

	h3 {
		text-align: center;
		font-weight: 800;
		margin-top: 100px;
	}

	.main-card {
		margin-top: 80px;
	}
`;

const uniqSubdomain = (): string => {
	return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

interface State {
	lookups: Lookup[];
	initialFinished?: boolean;
	duplicates: number;
}

interface Lookup {
	subdomain: string;
	loading: boolean;
	isOurs?: boolean;
	resolver?: string;
	country?: string;
	isp?: string;
}

export default class DnsLeakTest extends React.Component<any, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			lookups        : [],
			initialFinished: false,
			duplicates     : 0,
		};
	}

	static isOurServer(s: string, servers: iModelServers[]): boolean {
		for (let i = 0; i < servers.length; i++) {
			if (s == servers[i].ipv4 || s == servers[i].ipv6) {
				return true;
			}
		}
		return false;
	}

	perform(servers: iModelServers[]) {
		let ls: Lookup[] = [];
		const promises = [];
		//	first parallel run
		for (let i = 0; i < 21; i++) {
			const subdomain = uniqSubdomain();

			ls.push({
				subdomain: subdomain,
				loading  : true,
			});

			promises.push(new Promise((resolve, reject) => {
				fetch(`https://${subdomain}.check.dnsleak.dnsadblock.com/`).then((response) => {
					response.json().then((res: any) => {
						resolve({
							subdomain: subdomain,
							isp      : res.ISP,
							resolver : res.IP,
							country  : res.Country,
							isOurs   : DnsLeakTest.isOurServer(res.IP, servers),
						});
					});
				}).catch(resolve);
			}));
		}

		this.setState({
			lookups: ls,
		});

		Promise.all(promises).then((responses) => {
			for (let i = 0; i < promises.length; i++) {
				const response: any = responses[i];

				ls = ls.map((lookup) => {
					if (lookup.subdomain == response.subdomain) {
						return Object.assign(lookup, response, {
							loading: false,
						});
					}
					return lookup;
				});
			}

			const uniqueObjects: (Lookup | undefined)[] = [...new Set(ls.map(obj => obj.resolver))].map(resolver => {
				return ls.find(obj => obj.resolver === resolver);
			});

			// @ts-ignore
			this.setState({
				// @ts-ignore
				lookups        : uniqueObjects,
				initialFinished: true,
			});
		});

		const checkInterval = setInterval(() => {
			const subdomain = uniqSubdomain();

			if (!this.state.initialFinished) return;

			fetch(`https://${subdomain}.check.dnsleak.dnsadblock.com/`).then((response) => {
				response.json().then((res) => {
					const { lookups } = this.state;
					const existing = lookups.filter((l) => l?.resolver == res.IP).length > 1;

					if (!existing) {
						lookups.push({
							loading  : false,
							subdomain: subdomain,
							isp      : res.ISP,
							resolver : res.IP,
							country  : res.Country,
							isOurs   : DnsLeakTest.isOurServer(res.IP, servers),
						});
						this.setState({
							lookups   : lookups,
							duplicates: 0,
						});
					} else {
						this.setState({ duplicates: this.state.duplicates + 1 });
					}

					if (this.state.duplicates >= 10) {
						clearInterval(checkInterval);
					}
				});
			});


		}, 1000);
	}

	componentDidMount(): void {
		fetch(serversUrl).then((response) => {
			response.json().then((res) => {
				this.perform(res);
			});
		});
	}

	render() {
		const { lookups, duplicates } = this.state;
		const uniqueByIsp: any[] = [...new Set(lookups.map(obj => obj.isp))].map(isp => {
			return lookups.find(obj => obj.isp === isp);
		});

		const uniqueByResolver: any[] = [...new Set(lookups.map(obj => obj.resolver))].map(resolver => {
			return lookups.find(obj => obj.resolver === resolver);
		});

		const uniqueByCountry: any[] = [...new Set(lookups.map(obj => obj.country))].map(country => {
			return lookups.find(obj => obj.country === country);
		});

		return (
			<Layout>
				<SEO
					title="DNS leak test and server information"
					description={
						`DNS leak test. Check if your operating system is leaking out unwanted DNS servers. Our tests are fast and accurate.`
					}
				/>

				<Hero title={'DNS Leak Test'} style={{
					marginBottom: 50,
				}}/>

				<Wrapper>
					<h2>
						Check if your operating system is leaking out unwanted DNS servers
					</h2>
					<Row gutter={16}>
						<Col xs={24} sm={24} md={12} lg={8} style={{ marginTop: 12 }}>
							<Card>
								<Statistic
									title={<span>Unique <b>resolvers</b></span>}
									value={uniqueByResolver.length}
									valueStyle={{ fontSize: '2.7rem', fontWeight: 400 }}
								/>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} style={{ marginTop: 12 }}>
							<Card>
								<Statistic
									title={<span>Unique <b>providers</b></span>}
									value={uniqueByIsp.length}
									valueStyle={{
										fontSize  : '2.7rem',
										fontWeight: 400,
										color     : uniqueByIsp.length > 1 ? '#cf1322' : '#3f8600',
									}}
								/>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} style={{ marginTop: 12 }}>
							<Card>
								<Statistic
									title={<span>Unique <b>countries</b></span>}
									value={uniqueByCountry.length}
									valueStyle={{
										fontSize  : '2.7rem',
										fontWeight: 400,
										color     : uniqueByCountry.length > 1 ? '#cf1322' : '#3f8600',
									}}
								/>
							</Card>
						</Col>
					</Row>

					<Row style={{ background: 'white', marginTop: 20, flexDirection: 'row' }}>
						{uniqueByResolver.map((lookup, i) => {
							return <Col key={`l-` + i} xs={24} sm={24} md={12} lg={8}>
								<Card.Grid style={{ width: '100%', padding: 10 }}>
									<Skeleton
										loading={lookup.loading}
										active title={true}
										paragraph={false}
									>
										<Card
											size="small"
											bordered={false}

										>
											<Card.Meta
												title={
													<b style={{
														color: !lookup.isOurs ? '#cf1322' : '#3f8600',
													}}>{lookup.isOurs ? 'DnsAdBlock' : lookup.isp}</b>}
												description={lookup.resolver}
												avatar={lookup.country ? <ReactCountryFlag
													style={{
														fontSize  : '4em',
														lineHeight: '4em',
													}}
													countryCode={lookup.country?.toLowerCase()}
													svg/> : null}
											/>
										</Card>
									</Skeleton>
								</Card.Grid>
							</Col>;
						})}

						{duplicates < 10 &&
									<Col style={{ alignSelf: 'stretch' }} key={`l-1000`} xs={24} sm={24} md={12} lg={8}>
										<Card.Grid style={{ height: '100%', width: '100%', padding: '5px 10px' }}>
											<Skeleton
												loading={true}
												active title={true}
												paragraph={false}
											/>
											<Skeleton
												loading={true}
												active title={true}
												paragraph={false}
											/>
										</Card.Grid>
									</Col>}
					</Row>


					<p style={{ marginTop: 20, color: 'rgba(0, 0, 0, 0.45)' }}>
						If your DNS servers are not configured properly your requests
						will leak to other DNS providers such as your ISP. This can be
						problematic when using anonymity services because the location
						of the DNS servers will expose your real location.
					</p>

					<p style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
						If you changed your DNS servers and they don't appear in this
						list then something is not configured properly.
					</p>
				</Wrapper>

				<LeaksCallout/>

				<Pricing style={{
					marginTop   : 150,
					marginBottom: 50,
				}}/>

				<Callout style={{
					marginBottom: 50,
				}}/>

				<Faq style={{
					marginBottom: 50,
				}}/>
			</Layout>
		);
	}
}
